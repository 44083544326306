// @ts-strict-ignore
import Context from './Context'
import Month from './Month'
import { useDatepicker } from '@datepicker-react/hooks'
import { Box, CleanButton, Flex, OldHeading, Icon, theme } from '@gassan-ui'
import { useTranslation } from 'next-i18next'
import { rem } from 'polished'
import { FC, useRef } from 'react'

type Props = {
  onSetDate: (date: Date) => void
  isDateBlocked?: (date: Date) => boolean
  value: string
}

const Datepicker: FC<Props> = ({ onSetDate, value, isDateBlocked = (date: Date) => false }) => {
  const { t } = useTranslation('forms')
  const {
    firstDayOfWeek,
    activeMonths,
    isDateSelected,
    isDateHovered,
    isFirstOrLastSelectedDate,
    isDateFocused,
    focusedDate,
    onDateHover,
    onDateSelect,
    onDateFocus,
    goToPreviousMonths,
    goToNextMonths,
  } = useDatepicker({
    startDate: null,
    endDate: null,
    focusedInput: null,
    onDatesChange: () => false,
    numberOfMonths: 1,
    minBookingDays: 1,
    exactMinBookingDays: true,
  })

  const ref = useRef<HTMLElement>()
  const { year, month } = activeMonths[0]
  const prevDisabled = year === new Date().getFullYear() && month - 1 < new Date().getMonth()
  return (
    <Context.Provider
      value={{
        isDateSelected,
        isDateHovered,
        isFirstOrLastSelectedDate,
        isDateBlocked,
        isDateFocused,
        focusedDate,
        onDateHover,
        onDateSelect,
        onDateFocus,
      }}
    >
      <Box maxWidth="30rem" m="0 auto" ref={ref}>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          textAlign="center"
          m="0 auto 1.5rem"
          width={`calc(100% - ${rem(30)})`}
        >
          <CleanButton
            type="button"
            tabIndex={-1}
            onClick={goToPreviousMonths}
            disabled={prevDisabled}
            data-test-id="prev-month"
            aria-label="Previous month"
          >
            <Icon
              icon="arrow-left"
              color={prevDisabled ? theme.colors.shades[500] : theme.colors.shades[800]}
            />
          </CleanButton>

          <OldHeading variant="h5" as="span" mb="0">
            {t(`months.${month}`)} {year}
          </OldHeading>

          <CleanButton
            type="button"
            tabIndex={-1}
            onClick={goToNextMonths}
            aria-label="Next month"
            data-test-id="next-month"
          >
            <Icon icon="arrow-right" />
          </CleanButton>
        </Flex>
        {activeMonths.map((month) => (
          <Month
            prev={goToPreviousMonths}
            next={goToNextMonths}
            key={`${month.year}-${month.month}`}
            year={month.year}
            month={month.month}
            value={value}
            onSetDate={onSetDate}
            firstDayOfWeek={firstDayOfWeek}
          />
        ))}
      </Box>
    </Context.Provider>
  )
}

export default Datepicker
